<!-- 商業道徳 -->
<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/ja-合作与发展banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <!-- 中间内容区域 -->
    <div class="content">
      <h2 class="H2">商業道徳</h2>
      <img style="width: 100%;margin-top: 3rem;" src="@/assets/cooperation/commercial_ethics/ja-道德.png" alt="">
      <div class="text">
      
          <img src="@/assets/cooperation/commercial_ethics/ja-商业道德.png" alt="">
      
        <div class="text_1">
          <p style="text-indent: 2em;">{{ text_1 }}</p>
          <p style="text-indent: 2em;">{{ text_2 }}</p>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/ja-合作与发展banner.png" class="images">
      </van-swipe-item>
    </van-swipe>
    <!-- 内容区域 -->
    <div class="content">
      <img src="@/assets/cooperation/commercial_ethics/ja-道德.png" alt="" />
      <h2>盛泰-商業道徳</h2>
      <p>{{text_1}}</p>
      <p>{{text_2}}</p>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
  components: { foot, headers, move_header, move_foot },

  setup() {
    const route = useRoute();
    const state = reactive({
      text_1: '盛泰光電は企業の経営行為において、法律と道徳標準の最高準則を達成することに力を尽くし、外部監督と内部規則制度の要求を結びつけ、本準則の目的はすべての社員が商業活動においてどのような行為方式に従うべきかを理解し、最低の行為標準を確立することです。',
      text_2: '盛泰光電の企業経営活動及び社員行為の規範は企業の長期的な発展、健康的な発展、持続可能な経営を確保する基礎であり、また経営過程におけるすべての商業行為と社員の個人道徳準則に正式な指導を提供しています。'
    });
    onMounted(() => {
      let text = document.getElementsByClassName('text_1')
      console.log(text);
      setTimeout(()=>{
        text[0].style.display = 'block'
      },500)
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="less">
.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {

  width: 100%;

  .content {
    max-width: 1920px;
    margin: 0 auto;
    width: 70%;
        .H2 {
            margin-top: 50px;
            font-size: 1.7rem;
        }

        .H2::before {
            content: "";
            width: 30%;
            height: 2px;
            background-color: #c7000b;
            position: absolute;
            margin-top: 40px;
            left: 18%;
            transform: translateX(-50%);
            // top:55px;
            min-width: 35px;
            max-width: 35px;
        }
    .text {
      position: relative;
      box-sizing: border-box;
      margin: 50px 0;
      img{
        width: 100%;
      }
      .text_1 {
        position: absolute;
        left: 300px;top:200px;
        width: 60%;
        // letter-spacing: 4px;
        padding: 50px;
        font-size: 1.2rem;
        display: none;
      }
    }
  }
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px;
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;

    .content {
      padding: 30px;

      img {
        width: 100%;
      }
      p{
        font-size: 60px;
      }
            h2 {
                text-align: center;
                font-size: 80px;
            }

            h2::before {
                content: "";
                width: 30%;
                height: 8px;
                background-color: #c7000b;
                position: absolute;
                margin-top: 100px;
                left: 50%;
                transform: translateX(-50%);
                min-width: 100px;
                max-width: 100px;
            }
    }
  }
}
</style>